import React from "react";
import { Helmet } from "react-helmet";
import { Routes, Route } from 'react-router-dom';
// Screens
import Landing from "./screens/Landing.jsx";
import PrivacyPolicy from "./screens/PrivacyPolicy.jsx";
import Terms from "./screens/Terms.jsx";
import Contact from "./screens/Contact.jsx";
import { v1, v2 } from "./constants/versions";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Routes>
          <Route path="/toma-el-control-de-tu-negocio" element={<Landing version={v2}/>} />
          <Route path="/" element={<Landing version={v1}/>} />
          <Route path="/politica-de-privacidad" element={<PrivacyPolicy />} />
          <Route path="/terminos-y-condiciones" element={<Terms />} />
          <Route path="/contacto" element={<Contact />} />
       </Routes>
    </>
  );
}

