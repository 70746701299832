import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Footer from "../components/Sections/Footer"

export default function Landing({version}) {
  return (
    <>
      <TopNavbar version={version} isHome={true}/>
      <Header version={version}/>
      <Services version={version}/>
      <Projects version={version}/>
      <Pricing version={version}/>
      <Footer />
    </>
  );
}


