import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Link as ReactRouterLink } from 'react-router-dom'
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import BurgerIcon from "../../assets/svg/BurgerIcon";

import Logo from "../../assets/img/ic_logo_blue.png"
import { trackEvent } from "../../analytics/mixPanel";

export default function TopNavbar({isHome, version}) {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setY(window.scrollY)
    });
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const openBlog = () => {
    window.open("https://tinpos.com.co/blog")
  }

  const sendEventNavBarClickItem = (item) => {
    trackEvent("navbar_click_item", {item, version})
  }

  return (
    <>
      <Sidebar version={version} sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} isHome={isHome} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          
          {isHome ? <Link onClick={() => {sendEventNavBarClickItem("logo")}}  className="pointer flexNullCenter title" to="home" smooth={true}>
            <img src={Logo} alt="Tin Pos: Software Pos #1 en Colombia" style={{height: "36px"}}/>
            <h1 style={{ marginLeft: "15px", marginTop: "8px" }} className="font20 extraBold">
              Tin Pos - Colombia 🇨🇴
            </h1>
          </Link> : <ReactRouterLink className="pointer flexNullCenter title" to={"/"}>
            <img src={Logo} alt="Tin Pos: Software Pos #1 en Colombia" style={{height: "36px"}}/>
            <h1 style={{ marginLeft: "15px", marginTop: "8px" }} className="font20 extraBold">
              Tin Pos - Colombia 🇨🇴
            </h1>
          </ReactRouterLink>}
          {isHome && <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>}
          {isHome && <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link onClick={() => {sendEventNavBarClickItem("home")}} activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                Inicio
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link onClick={() => {sendEventNavBarClickItem("characteristics")}} activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                Características
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link onClick={() => {sendEventNavBarClickItem("views")}} activeClass="active" style={{ padding: "10px 15px" }} to="projects" spy={true} smooth={true} offset={-80}>
                Vistas
              </Link>
            </li>
            {/*<li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="blog" spy={true} smooth={true} offset={-80}>
                Blog
              </Link>
  </li>*/}
            <li className="semiBold font15 pointer">
              <Link onClick={() => {sendEventNavBarClickItem("prices")}} activeClass="active" style={{ padding: "10px 15px" }} to="pricing" spy={true} smooth={true} offset={-80}>
                Precios
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <ReactRouterLink onClick={() => {sendEventNavBarClickItem("contact")}} activeClass="active" style={{ padding: "10px 15px" }} to={"/contacto"} spy={true} smooth={true} offset={-80}>
                Contacto
              </ReactRouterLink>
            </li>
            <li className="semiBold font15 pointer">
              <ReactRouterLink onClick={() => {sendEventNavBarClickItem("blog")}} activeClass="active" style={{ padding: "10px 15px" }} to={{pathname: "/blog/"}} target="_blank">
                Blog
              </ReactRouterLink>
            </li>
          </UlWrapper>}
          {/*<UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <a href="/" style={{ padding: "10px 30px 10px 0" }}>
                Log in
              </a>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                Get Started
              </a>
            </li>
</UlWrapperRight>*/}
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;

