export default {
    v1: {
        title: "Genera Facturas Electrónicas con Tin Pos desde tu Móvil.",
        description: "Agiliza tu contabilidad y cumple con la normativa colombiana sin complicaciones. Tin Pos te permite emitir facturas electrónicas directamente desde tu móvil, garantizando cumplimiento y eficiencia. Descarga nuestra app y empieza a facturar de manera rápida y segura.",
        reviewText: "Gracias a Tin Pos, facturar desde mi móvil es rápido y cumple con la DIAN. ¡Un cambio positivo para mi negocio!",
        reviewOwner: "Laura C., Boutique Flores.",
        featuresTitle: "Funcionalidades de Tin Pos: Facturación Electrónica",
        featuresDescription: "Nuestro sistema POS ofrece una solución integral de facturación electrónica en Colombia, diseñada para optimizar tu negocio. Entre sus funcionalidades destacan:",
    },
    v2: {
        title: "Optimiza la Administración de tu Negocio desde tu Móvil con Tin Pos",
        description: "Haz que la administración de tu negocio sea simple y eficiente. Tin Pos te ayuda a organizar inventarios, controlar ventas y mantener registros de clientes desde una app intuitiva. Empieza a gestionar tu negocio de manera inteligente y práctica.",
        reviewText: "Con Tin Pos, gestionar mi tienda es mucho más fácil. Puedo controlar ventas e inventarios desde mi móvil sin complicaciones. ¡Una herramienta clave para mi negocio!",
        reviewOwner: "Camila A., Celumaster.",
        featuresTitle: "Herramientas Clave para Administrar tu Negocio con Tin Pos",
        featuresDescription: "Con Tin Pos, gestionar tu negocio es fácil y eficiente. Desde el control de ventas hasta la administración de inventarios, descubre una herramienta diseñada para simplificar cada proceso. Sus funcionalidades incluyen:"
    }
}