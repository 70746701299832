import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import ElectronicInvoice from "../../assets/svg/Services/ElectronicInvoice";
import Inventory from "../../assets/svg/Services/Inventory";
import Sales from "../../assets/svg/Services/Sales";
import Users from "../../assets/svg/Services/Users";

import IcElectronicInvoice from "../../assets/img/ic_electronic_invoice.png"
import icInventory from "../../assets/img/ic_inventory.png"
import IcSales from "../../assets/img/ic_sales.png"
import IcUsersAndRols from "../../assets/img/ic_users_and_rols.png"

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon = <img src={icon} alt="Tin Pos: Software Pos #1 en Colombia" style={{height: "72px"}}/>;


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;